import React from 'react'
import useSearch from '../../../../GlobalSearch/useSearch'
import { Col, DropdownItem, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import SearchItem from '../../../../GlobalSearch/SearchItem'

interface ResultsProps {
  term: string
  onClick?: React.MouseEventHandler<HTMLElement>
}

const Results: React.FC<ResultsProps> = (props) => {
  const { term, onClick } = props
  const { items, pages, status } = useSearch({ term, page: 1, perPage: 5 })
  const { pageCount, perPage, itemCount } = pages[0]?.search?.pageInfo ?? {}

  if (status === 'loading') {
    return (
      <Row>
        <Col className='text-center'>
          Loading...
        </Col>
      </Row>
    )
  }

  if (status === 'error') {
    throw new Error('Error fetching search results')
  }

  if (status === 'success' && items.length === 0) {
    return (
      <Row>
        <Col className='text-center'>
          No Results Found
        </Col>
      </Row>
    )
  }

  return (
    <>
      {items.map(item => {
        if (item.url === undefined || item.url === null) {
          return null
        }

        return (
          <DropdownItem
            className='py-2'
            key={item._id}
            style={{
              scrollSnapAlign: 'start'
            }}
            onClick={onClick}
            tag={Link}
            to={item.url}
          >
            <SearchItem item={item} />
          </DropdownItem>
        )
      })}
      {pageCount !== undefined && pageCount !== null && pageCount > 1
        ? (
          <DropdownItem text className='text-center'>
            <Link to={{ pathname: '/search', search: `?s=${term}` }}>View More ({perPage} of {itemCount})</Link>
          </DropdownItem>
          )
        : null}
    </>
  )
}

export default Results
