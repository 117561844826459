import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { DropdownItem } from 'reactstrap'

const SearchError: React.FC = () => {
  return (
    <DropdownItem className='text-center' text>
      <FontAwesomeIcon icon={faTriangleExclamation} className='text-warning me-2' />
      There was an error fetching search results. Please try again.
    </DropdownItem>
  )
}

export default SearchError
